interface Service {
  title: string;
  description: string;
  icon?: string;
}

const servicesList: Service[] = [
  {
    title: "Infrastructure Planning",
    description:
      "Expert guidance in designing and implementing scalable blockchain infrastructure. We help you make informed decisions about node architecture, networking, and security measures.",
  },
  {
    title: "Infrastructure Hosting",
    description:
      "Blink Labs hosted infrastructure uses best in class methods for scalable blockchain infrastructure, monitoring, and reporting. We work with you to build the perfect solution, managed by us.",
  },
  {
    title: "Off-chain Audits",
    description:
      "Comprehensive analysis of your off-chain systems and infrastructure. We identify potential vulnerabilities, optimize performance, and ensure seamless integration with on-chain components.",
  },
  {
    title: "Software Support Contracts",
    description:
      "Dedicated technical support and maintenance for your blockchain applications. Our team provides regular updates, bug fixes, and continuous improvement recommendations.",
  },
];

const Services = () => {
  return (
    <div className="min-h-screen">
      <div className="max-w-7xl mx-auto py-24 px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <p className="max-w-xl mt-5 mx-auto text-xl text-gray-200">
            Professional blockchain services tailored to your needs
          </p>
        </div>

        <div className="mt-14 grid gap-12 md:grid-cols-2 lg:grid-cols-2">
          {servicesList.map((service, index) => (
            <div key={index} className="relative group h-full">
              <div className="absolute -inset-4 bg-gradient-to-r from-indigo-600 to-blue-600 rounded-2xl blur-2xl opacity-0 group-hover:opacity-20 transition duration-1000 group-hover:duration-200" />
              <div className="relative h-full px-8 py-10 bg-opacity-10 bg-white backdrop-blur-lg border border-gray-200/20 rounded-lg leading-none flex flex-col items-top space-y-6">
                <div className="space-y-2">
		{/*<p className="text-indigo-300 text-sm font-medium">
                    {`Service ${index + 1}`}
                  </p> */}
                  <h3 className="text-2xl font-bold text-white">
                    {service.title}
                  </h3>
                </div>
                <p className="text-white leading-relaxed text-md flex-grow">
                  {service.description}
                </p>
                {/* <div className="pt-4">
                  <button className="inline-flex items-center text-sm font-semibold text-indigo-300">
                    Learn more &rarr;
                  </button>
                </div> */}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Services;
