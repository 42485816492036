import './footer.scss';
import { getLink } from 'src/utility/functions';
import {
  companyLinks,
  contactLinks,
  serviceLinks,
  openSourceLinks,
} from 'src/data/project-data';

function SiteFooter() {
  return (
    <footer className="border">
      <div className="outer-flex footer-content">
        <div className="inner-flex columns">
          <div className="column">
            <ul>
              <li>{getLink({ url: '/', innerElement: 'Home' })}</li>
              <li>
                {getLink({
                  url: openSourceLinks.url,
                  icon: openSourceLinks.icon,
                  innerElement: openSourceLinks.name,
                })}
              </li>
              <li>
                {getLink({
                  url: serviceLinks.url,
                  icon: serviceLinks.icon,
                  innerElement: serviceLinks.name,
                })}
              </li>
            </ul>
          </div>
          <div className="column">
            <ul>
              <li>{companyLinks.name}</li>
              {companyLinks.subMenu?.map((subNavLink, subIndex) => (
                <li key={subIndex}>
                  {getLink({
                    url: subNavLink.url,
                    icon: subNavLink.icon,
                    innerElement: subNavLink.name,
                  })}
                </li>
              ))}
            </ul>
          </div>
          <div className="column">
            <ul>
              <li>{contactLinks.name}</li>
              {contactLinks.subMenu?.map((subNavLink, subIndex) => (
                <li key={subIndex}>
                  {getLink({
                    url: subNavLink.url,
                    icon: subNavLink.icon,
                    innerElement: subNavLink.name,
                  })}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default SiteFooter;
