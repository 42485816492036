import { toast } from "react-hot-toast";

export const blinkLabsToast = (type: "success" | "error", message: string) => {
  const isSuccess = type === "success";
  const bgColor = "bg-white";
  const textColor = isSuccess ? "text-green-600" : "text-red-600";
  const borderColor = "border-gray-300";

  toast.custom((t: any) => (
    <div
      className={`${
        t.visible ? "animate-enter" : "animate-leave"
      } max-w-md w-full ${bgColor} shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
    >
      <div className="flex-1 w-0 p-4">
        <div className="flex items-start">
          <div className="flex-shrink-0 pt-0.5 flex justify-center">
          </div>
          <div className="ml-3 flex-1">
            <p className={`text-md font-bold ${textColor}`}>
              {isSuccess ? "Success" : "Error"}
            </p>
            <p className={`mt-1 text-md ${textColor}`}>{message}</p>
          </div>
        </div>
      </div>
      <div className={`flex border-l ${borderColor}`}>
        <button
          onClick={() => toast.dismiss(t.id)}
          className="w-full border border-transparent rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium text-black "
        >
          Close
        </button>
      </div>
    </div>
  ));
};
