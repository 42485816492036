import { JobData, JobsDataGroup } from "../data/project-data";

import "./job.scss";
import SiteButton from "./site-button";
import JobApplicationForm from "./job-application-form";
import { useState } from "react";
import { Toaster } from "react-hot-toast";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSquareMinus,
  faSquarePlus,
} from '@fortawesome/free-solid-svg-icons';

function Job({
  jobData,
  jobsDataGroup,
}: {
  jobData: JobData;
  jobsDataGroup: JobsDataGroup;
}) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div>
      <section className="job">
        <h3>{jobData.title}</h3>
        <p>{jobData.desc}</p>
        {isExpanded && <p>{jobData.longDesc}</p>}
        <div className="flex flex-col items-start space-y-2">
          <button
            onClick={() => setIsExpanded(!isExpanded)}
            className="text-white hover:text-gray-400 font-medium transition-colors duration-200 mb-4"
          >
            <FontAwesomeIcon icon={isExpanded ? faSquareMinus : faSquarePlus} /> {isExpanded ? "Show Less" : "Learn More"}
          </button>
          <div onClick={() => setIsModalOpen(true)}>
            <SiteButton url="#" label={jobData.linkTitle || "Apply"} />
          </div>
        </div>
      </section>

      <JobApplicationForm
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        jobsDataGroup={jobsDataGroup}
        selectedJob={jobData}
      />
      <Toaster position="top-center" reverseOrder={false} />
    </div>
  );
}

export default Job;
