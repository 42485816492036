import React, { useState, useEffect } from "react";
import emailjs from "@emailjs/browser";

import { blinkLabsToast } from "./blinklab-toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { JobData, JobsDataGroup } from "../data/project-data";

interface JobApplicationFormProps {
  isOpen: boolean;
  onClose: () => void;
  jobsDataGroup: JobsDataGroup;
  selectedJob: JobData;
}

const JobApplicationForm = ({
  isOpen,
  onClose,
  selectedJob,
}: JobApplicationFormProps) => {
  const [formData, setFormData] = useState({
    from_name: "",
    from_email: "",
    message: "",
    cv_attachment: null as File | null,
    job_title: selectedJob.title,
    job_id: selectedJob.id,
  });

  const serviceId = process.env.REACT_APP_EMAIL_SERVICE_ID || "";
  const templateId = process.env.REACT_APP_EMAIL_TEMPLATE_ID || "";
  const publicKey = process.env.REACT_APP_EMAIL_PUBLIC_KEY || "";

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const { name, value } = e.target;
    const fieldMapping: { [key: string]: string } = {
      name: 'from_name',
      email: 'from_email',
      message: 'message'
    };
    const stateField = fieldMapping[name] || name;
    setFormData({ ...formData, [stateField]: value });
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];

    if (!file) {
      return;
    }

    if (file.size > 500 * 1024) {
      blinkLabsToast("error", "CV file must be smaller than 500KB");
      e.target.value = "";
      return;
    }

    setFormData(prev => ({ ...prev, cv_attachment: file }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    // Check for existing submissions
    const submissions = JSON.parse(localStorage.getItem("jobApplications") || "{}");
    if (submissions[selectedJob.id]) {
      blinkLabsToast("error", "You have already applied for this position.");
      return;
    }

    // Check for submission count in the last 24 hours
    const recentSubmissions = Object.values(submissions).filter((timestamp: unknown) => {
      return typeof timestamp === 'number' && Date.now() - timestamp < 24 * 60 * 60 * 1000;
    });

    if (recentSubmissions.length >= 3) {
      blinkLabsToast("error", "Maximum applications (3) reached for today. Please try again tomorrow.");
      return;
    }

    let cvBase64 = "";
    if (formData.cv_attachment) {
      try {
        cvBase64 = await new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(formData.cv_attachment as File);
          reader.onload = () => {
            const base64String = reader.result as string;
            resolve(base64String.split(',')[1]);
          };
          reader.onerror = (error) => reject(error);
        });
      } catch (error) {
        blinkLabsToast("error", "Failed to process CV file.");
        return;
      }
    }

    const templateParams = {
      from_name: formData.from_name,
      from_email: formData.from_email,
      message: formData.message,
      cv_attachment: cvBase64,
      job_title: formData.job_title,
      job_id: formData.job_id,
    };

    emailjs.send(serviceId, templateId, templateParams, publicKey)
      .then(
        () => {
          submissions[selectedJob.id] = Date.now();
          localStorage.setItem("jobApplications", JSON.stringify(submissions));
          blinkLabsToast("success", "Application submitted successfully!");
        },
        () => {
          blinkLabsToast("error", "Failed to submit application.");
        }
      );
  };

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }

    return () => {
      document.body.style.overflow = "unset";
    };
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-gray-200 p-8 rounded-lg relative w-[90%] max-w-[500px] max-h-[90vh] overflow-y-auto shadow-lg backdrop-filter backdrop-blur-xl bg-opacity-30 border border-white border-opacity-20">
        <button
          className="absolute top-4 right-4 text-2xl border-none bg-transparent cursor-pointer text-white"
          onClick={onClose}
        >
          <FontAwesomeIcon icon={faXmark} />
        </button>
        <form
          onSubmit={handleSubmit}
          className="flex flex-col gap-4"
          encType="multipart/form-data"
        >
          <div className="text-2xl font-bold text-white">
            Job Application Form
          </div>
          <div className="flex flex-col gap-2">
            <div className=" text-white font-bold text-2xl ">
              {selectedJob.title}
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <label htmlFor="name" className="font-medium text-white">
              Name
            </label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.from_name}
              onChange={handleChange}
              required
              className="p-3 border rounded-md text-black focus:outline-none focus:border-blue-800"
            />
          </div>
          <div className="flex flex-col gap-2">
            <label htmlFor="email" className="font-medium text-white">
              Email Address
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.from_email}
              onChange={handleChange}
              required
              className="p-3 border rounded-md text-black focus:outline-none focus:border-blue-800"
            />
          </div>
          <div className="flex flex-col gap-2">
            <label htmlFor="message" className="font-medium text-white">
              Message
            </label>
            <textarea
              id="message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              className="p-3 border rounded-md min-h-[120px] resize-vertical text-black focus:outline-none focus:border-blue-800"
            />
          </div>
          <div className="flex flex-col gap-2">
            <label htmlFor="cv" className="font-medium text-white">
              Upload CV (optional, max 500KB)
            </label>
            <input
              type="file"
              id="cv"
              name="cv"
              onChange={handleFileChange}
              accept=".pdf,.doc,.docx"
              className="hidden"
            />
            <label
              htmlFor="cv"
              className="p-2 border rounded-md bg-blue-50 bg-opacity-5 cursor-pointer text-white hover:bg-opacity-10 transition-all"
            >
              {formData.cv_attachment ? formData.cv_attachment.name : "Choose File"}
            </label>
            {formData.cv_attachment && (
              <div className="text-sm text-white opacity-70">
                Selected file: {formData.cv_attachment.name}
              </div>
            )}
          </div>
          <button
            type="submit"
            className="mt-2 bg-[#D97706] text-white py-3 px-6 rounded-md font-medium cursor-pointer transition-colors hover:bg-[#D97706]/80"
          >
            Submit Application
          </button>
        </form>
      </div>
    </div>
  );
};

export default JobApplicationForm;
